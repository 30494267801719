<template>
  <div>
    <el-form
      ref="form"
      label-width="180px"
      @submit.native.prevent
    >
      <!-- <el-form-item label="Type">
        <el-radio-group
          v-model="search.isNew"
          @input="isNew => setSearchToLink({ isNew })"
        >
          <el-radio-button :label="1">
            New Digital SAT
          </el-radio-button>
          <el-radio-button :label="0">
            Old Paper-Based SAT
          </el-radio-button>
        </el-radio-group>
      </el-form-item> -->
      <!-- <el-form-item label="Subjects" v-if="false">
        <el-checkbox-group
          v-model="search.subjects"
          @input="subjects => setSearchToLink({ subjects })"
        >
          <el-checkbox-button
            :key="satSubject.id"
            v-for="satSubject in subjectsNow"
            :label="satSubject.id"
          >
            {{ titleCase(satSubject.name) }}
          </el-checkbox-button>
        </el-checkbox-group>
      </el-form-item> -->
      <el-form-item label="Source" v-if="false">
        <div class="d-flex">
          <div>
            <el-radio-group
              v-model="search.source"
              @change="setSearchToLink({ source: search.source })"
            >
              <el-radio-button label="0" value="1">
                Official
              </el-radio-button>
              <el-radio-button label="1" value="1">
                SATMocks
              </el-radio-button>
              <el-radio-button :label="2" v-if="isAdmin && false">
                Company
              </el-radio-button>
            </el-radio-group>
          </div>
          <div v-if="isAdmin && false" class="ml-2" style="flex:1">
            <el-select
              v-if="source.indexOf(2) > -1"
              v-model="company"
              multiple
              style="width: 100%;"
            >
              <el-option
                :key="company.id"
                v-for="company in companies"
                :label="company.name"
                :value="company.id"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="Difficulty">
        <el-radio-group
          v-model="search.difficulty"
          @change="setSearchToLink({ difficulty: search.difficulty })"
        >
          <el-radio-button :label="null" :value="null">
            All
          </el-radio-button>
          <el-radio-button
            :key="index"
            v-for="(difficulty, index) in DifficultyArr"
            :label="difficulty.key"
            :value="difficulty.key"
          >
            {{ titleCase(difficulty.name) }}
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <span slot="label">
          Skills
          <el-tooltip
            class="item"
            effect="dark"
            content="Edit Skills"
            placement="top"
            v-if="isAdmin"
          >
            <router-link class="ml-2" :to="{ name: 'QuestionTags' }">
              <el-button type="success" size="mini">
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </span>
        <el-select
          v-model="search.tags"
          multiple
          filterable
          style="width:100%"
          @change="setSearchToLink({ tags: search.tags })"
        >
          <el-option
            v-for="tag in tags"
            :key="tag.id"
            :label="`[${tag.type}] - ${tag.name}`"
            :value="tag.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Keywords">
        <el-input
          ref="filterInput"
          placeholder="keyword"
          v-model="search.search"
          @keyup.enter.native="setSearchToLink({ search: search.search })"
        >
          <el-button
            el-button
            slot="append"
            icon="el-icon-search"
            @click="setSearchToLink({ search: search.search })"
          >
            Search
          </el-button>
        </el-input>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { helper } from "@ivy-way/material";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import Company from "@/apis/company";

export default {
  metaInfo() {
    return {
      title: "Questions - " + this.CompanyName
    };
  },

  components: {},

  mixins: [Common],

  props: ["search", "isAdmin", "isCompany", "isTeacher"],
  data() {
    return {
      subjects: [],
      tags: [],
      companies: [],
      difficulty: null,
      source: null,
      company: null
    };
  },
  computed: {
    helper() {
      return helper;
    },
    id() {
      return this.$route.query.id;
    },
    isAdd() {
      return this.id;
    },
    subjectsNow() {
      let subjects = [];
      // if (this.search.isNew === 1) {
      //   subjects = [[4], this.subjects[2]];
      // } else {
      //   subjects = [
      //     this.subjects[0],
      //     this.subjects[1],
      //     this.subjects[2],
      //     this.subjects[3]
      //   ];
      // }
      subjects = this.subjects;
      return subjects;
    },
    examTagTypes() {
      return [
        { name: "Gammar", key: "question_grammar" },
        { name: "Math", key: "question_math" },
        { name: "Reading", key: "question_reading" },
        { name: "Science", key: "question_science" }
      ];
    },
    DifficultyArr() {
      return [
        { name: "Beginner", key: "1" },
        { name: "Easy", key: "2" },
        { name: "Medium", key: "3" },
        { name: "Hard", key: "4" },
        { name: "Super Advanced", key: "5" },
        { name: "(Not Set)", key: "0" }
      ];
    },
    page() {
      let page = 1;
      if (this.$route.query.page === undefined) {
        page = 1;
      } else {
        page = this.$route.query.page;
      }
      return parseInt(page);
    }
  },
  watch: {},

  async mounted() {
    // const subjects = await SAT.getSubjects();
    // this.subjects = subjects.sat_subjects;
    this.getTags();
    this.getCompanies();
  },

  methods: {
    async getCompanies() {
      const res = await Company.listCompany();
      this.companies = res.companies.data;
    },
    async getTags() {
      const res = await SAT.getSATQuestionTags();
      this.tags = res.sat_question_tags;
    },
    setSearchToLink(data) {
      this.$emit("setSearchToLink", data);
    }
  }
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
::v-deep .el-tabs--border-card {
  box-shadow: none;
}
::v-deep .el-select__tags {
  /* height: 40px;
  line-height: 40px; */
  margin: auto 0;
}
.search {
  margin: 20px 0px;
}

.questions {
  margin: 20px 0px;
}

.leftQuestionCountTip {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.add-question-btn {
  position: fixed;
  left: 0px;
  z-index: 99;
  background: var(--themeColor);
  color: #ffffff;
  border-radius: 0px 4px 4px 0px;
  height: 40px;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
</style>
