<template>
  <div ref="ivy-question" class="question-card">
    <el-card
      v-if="false"
      :class="showQuestion ? 'ivy-question show-FullQuestion' : 'ivy-question'"
      :shadow="isEdit ? 'hover' : 'never'"
      style="margin:30px 0"
    >
      <div class="question-content" style="margin-top:10px">
        <div>
          <template v-if="isEdit">
            <div style="float: right; padding: 3px 0">
              <el-tooltip
                class="item"
                effect="dark"
                content="Edit"
                placement="top"
              >
                <router-link
                  :to="{
                    name: 'SatEditQuestion',
                    query: {
                      questionId: question.id
                    }
                  }"
                >
                  <el-button type="success" size="small">
                    <i class="fas fa-edit"></i>
                  </el-button>
                </router-link>
              </el-tooltip>
              <el-tooltip
                class="item"
                effect="dark"
                content="Add"
                placement="top"
                v-if="isAdd && isEdit && !question.isIn"
              >
                <el-button
                  type="success"
                  size="small"
                  @click="$emit('addQuestion')"
                >
                  <i class="fas fa-plus-circle"></i>
                </el-button>
              </el-tooltip>
              <el-button
                type="info"
                size="small"
                :disabled="true"
                v-if="question.isIn"
              >
                <span>Added</span>
              </el-button>
            </div>
          </template>
        </div>
      </div>
      <div class="question-content-title">
        <kbd v-if="order">{{ order }} &nbsp;</kbd>
        <h6
          class="mt-1 mb-2"
          v-if="question.sat_passage && question.sat_passage.content !== ''"
          v-html="changeColor(question.sat_passage.content)"
        ></h6>
        <div>
          <b v-html="changeColor(question.content)"></b>
        </div>
      </div>
      <div>
        <ul class="ivy-question-options" v-if="question.type === 'default'">
          <li
            class="option"
            v-for="(option, index) in question.sat_options"
            :key="index"
          >
            <div v-show="showAnswer" style="width:1.5rem">
              <span class="text-success">
                <i
                  v-if="isAnswer(question.sat_answers, option.letter)"
                  class="fas fa-check-circle"
                ></i>
              </span>
            </div>
            <span style="margin-right: 4px">{{ `${option.letter}) ` }}</span>
            <div class="optionText" v-html="changeColor(option.title)"></div>
          </li>
        </ul>
        <template v-else>
          <div class="mt-2" v-show="showAnswer">
            <b>Answer:</b>
            <span
              class="ml-1"
              v-for="(answer, index) in question.sat_answers"
              :key="index"
            >
              {{ answer.answers[0] }}
              <span v-if="index < question.sat_answers.length - 1">
                ,
              </span>
            </span>
          </div>
        </template>
      </div>
      <div class="mt-4" v-if="questionTags">
        <div
          v-for="(tag_analysis, key, index) in questionTags"
          :key="index"
          class="pb-2"
        >
          <b>{{ key }}</b
          >:
          <el-tag
            v-for="tag in tag_analysis"
            :key="tag.id"
            type="success"
            size="small"
            class="ivy-tag"
          >
            <b>{{ tag.name }}</b>
          </el-tag>
        </div>
      </div>
      <div class="mt-2" v-show="showExplanation">
        <b>
          Explanation:
        </b>
        <div v-html="question.explanation"></div>
      </div>
      <router-link
        :to="{
          name: 'QuestionPreview',
          params: {
            questionId: question.id
          }
        }"
      >
        <span
          :class="
            question.exam_questions && question.exam_questions.length > 0
              ? 'is-warning passage-title'
              : 'passage-title'
          "
        >
          <i class="fas fa-eye"></i>
          <!-- Preview -->
          <span v-if="question.exam_questions" class="m-0">
            Seen in
            <el-popover placement="top-start" trigger="hover">
              <div style="width: 450;overflow: auto;">
                <el-tag
                  v-for="tag in question.exam_questions"
                  :key="tag.id"
                  type="success"
                  size="small"
                  effect="dark"
                  class="ml-1 mr-1 mb-1"
                >
                  {{ `${tag.exam.title}` }}
                </el-tag>
              </div>
              <b
                class="text-success"
                slot="reference"
                style="text-decoration:underline;cursor: pointer;"
                >{{ question.exam_questions.length }}</b
              >
            </el-popover>
            <span v-if="question.exam_questions.length > 1"> Tests.</span>
            <span v-else> Test.</span>
          </span>
        </span>
      </router-link>
    </el-card>
    <!-- <hr /> -->
    <!-- <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="date" label="Question" width="180">
        <template slot-scope="scope">
          <div>
            <div class="show-question">
              <div class="show-question-kbd">
                <kbd v-if="order">{{ order }} &nbsp;</kbd>
              </div>
              <div class="question-content-title mt-3">
                <kbd v-if="order">{{ order }} &nbsp;</kbd>
                <h6
                  class="mt-1 mb-1"
                  ref="ivy-question"
                  v-if="
                    showPassage &&
                      question.sat_passage &&
                      question.sat_passage.content !== ''
                  "
                  v-html="changeColor(question.sat_passage.content)"
                ></h6>
                <div class="mt-2">
                  <b v-html="changeColor(question.content)"></b>
                </div>
              </div>
            </div>
            <div class="options-box mb-2">
              <div v-if="showOptions">
                <ul
                  class="ivy-question-options"
                  v-if="question.type === 'default'"
                >
                  <li
                    class="option"
                    v-for="(option, index) in question.sat_options"
                    :key="index"
                  >
                    <div v-show="showAnswer" style="width:1.5rem">
                      <span class="text-success">
                        <i
                          v-if="isAnswer(question.sat_answers, option.letter)"
                          class="fas fa-check-circle"
                        ></i>
                      </span>
                    </div>
                    <span style="margin-right: 4px">{{
                      `${option.letter}) `
                    }}</span>
                    <div
                      class="optionText"
                      v-html="changeColor(option.title)"
                    ></div>
                  </li>
                </ul>
                <template v-else>
                  <div class="mt-2">
                    <b>Answer:</b>
                    <span
                      class="ml-1"
                      v-for="(answer, index) in question.sat_answers"
                      :key="index"
                    >
                      {{ answer.answers[0] }}
                      <span v-if="index < question.sat_answers.length - 1">
                        ,
                      </span>
                    </span>
                  </div>
                </template>
              </div>
              <div
                v-if="
                  showLabels && (question.sat_question_tags || []).length > 0
                "
                class="mb-2 mt-2"
              >
                <b>
                  Topics:
                  <el-tag
                    v-for="tag in question.sat_question_tags"
                    :key="tag.id"
                    type="success"
                    size="small"
                    class="ivy-tag"
                  >
                    {{ `[${tag.type}] - ${tag.name}` }}
                  </el-tag>
                </b>
              </div>
              <div
                v-if="
                  showExplanation &&
                    question.explanation &&
                    question.explanation !== ''
                "
                class="mb-2 mt-2"
              >
                <b class="mr-2">Explanation:</b>
                <span v-html="question.explanation"></span>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Subject" width="180">
      </el-table-column>
      <el-table-column prop="Tests" label="地址"> </el-table-column>
      <el-table-column prop="Actions" label="地址"> </el-table-column>
    </el-table> -->
    <div class="d-flex pt-3 pb-3" style="border-top: 1px solid rgba(0,0,0,.1)">
      <div
        :class="showQuestion ? 'd-flex show-FullQuestion' : 'd-flex'"
        style="flex: 1;flex-direction:column;align-items: normal;justify-content: center;"
      >
        <div class="show-question">
          <!-- <div class="show-question-kbd">
            <kbd v-if="order">{{ order }} &nbsp;</kbd>
          </div> -->
          <div class="question-content-title">
            <kbd v-if="order">{{ order }} &nbsp;</kbd>
            <div
              class="mt-1 mb-2"
              ref="ivy-question"
              v-if="
                showPassage &&
                  question.sat_passage &&
                  question.sat_passage.content !== ''
              "
              v-html="changeColor(question.sat_passage.content)"
            ></div>
            <div>
              <b v-html="changeColor(question.content)"></b>
            </div>
          </div>
        </div>
        <div>
          <div v-if="showOptions">
            <ul class="ivy-question-options" v-if="question.type === 'default'">
              <li
                class="option"
                v-for="(option, index) in question.sat_options"
                :key="index"
              >
                <div v-show="showAnswer" style="width:1.5rem">
                  <span class="text-success">
                    <i
                      v-if="isAnswer(question.sat_answers, option.letter)"
                      class="fas fa-check-circle"
                    ></i>
                  </span>
                </div>
                <span style="margin-right: 4px">{{
                  `${option.letter}) `
                }}</span>
                <div
                  class="optionText"
                  v-html="changeColor(option.title)"
                ></div>
              </li>
            </ul>
            <template v-else>
              <div class="mt-2">
                <b>Answer:</b>
                <span
                  class="ml-1"
                  v-for="(answer, index) in question.sat_answers"
                  :key="index"
                >
                  {{ answer.answers[0] }}
                  <span v-if="index < question.sat_answers.length - 1">
                    ,
                  </span>
                </span>
              </div>
            </template>
          </div>
          <!-- <div
            v-if="showLabels && (question.sat_question_tags || []).length > 0"
            class="mb-2 mt-2"
          >
            <b>
              Topics:
              <el-tag
                v-for="tag in question.sat_question_tags"
                :key="tag.id"
                type="success"
                size="small"
                class="ivy-tag"
              >
                {{ `[${tag.type}] - ${tag.name}` }}
              </el-tag>
            </b>
          </div> -->
          <div class="mt-1" v-if="showLabels && questionTags">
            <div
              v-for="(tag_analysis, key, index) in questionTags"
              :key="index"
              class="pb-2"
            >
              <b>{{ key }}</b
              >:
              <el-tag
                v-for="tag in tag_analysis"
                :key="tag.id"
                type="success"
                size="small"
                class="ivy-tag"
              >
                <b>{{ tag.name }}</b>
              </el-tag>
            </div>
          </div>
          <div
            v-if="
              showExplanation &&
                question.explanation &&
                question.explanation !== ''
            "
            class="mb-2"
          >
            <b class="mr-2">Explanation:</b>
            <span v-html="question.explanation"></span>
          </div>
        </div>
      </div>
      <!-- <div class="buttons" style="width: 200px;"></div> -->
      <div class="buttons" style="width: 300px;">
        <span v-if="question.exam_questions" class="m-0">
          Seen in
          <el-popover placement="left" trigger="hover" width="450">
            <div>
              <el-tag
                v-for="tag in question.exam_questions"
                :key="tag.id"
                type="success"
                size="small"
                effect="dark"
                class="ml-1 mr-1 mb-1"
              >
                {{ `${tag.exam.title}` }}
              </el-tag>
            </div>
            <b
              class="text-success"
              slot="reference"
              style="text-decoration:underline;cursor: pointer;"
              >{{ question.exam_questions.length }}</b
            >
          </el-popover>
          <span v-if="question.exam_questions.length > 1"> Tests.</span>
          <span v-else> Test.</span>
        </span>
      </div>
      <div class="buttons" style="width: 200px;">
        <template v-if="isAdd">
          <el-tooltip
            class="item"
            effect="dark"
            content="Add"
            placement="top"
            v-if="isEdit && !question.isIn"
          >
            <el-button
              type="success"
              size="small"
              @click="$emit('addQuestion')"
            >
              <i class="fas fa-plus-circle"></i>
            </el-button>
          </el-tooltip>
          <el-button
            type="info"
            size="small"
            :disabled="true"
            v-if="question.isIn"
          >
            <span>Added</span>
          </el-button>
        </template>
        <template v-else>
          <el-tooltip
            class="item"
            effect="dark"
            content="Preview"
            placement="top"
          >
            <router-link
              :to="{
                name: 'QuestionPreview',
                params: {
                  questionId: question.id
                }
              }"
            >
              <el-button type="success" size="mini">
                <i class="fas fa-eye"></i>
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip class="item" effect="dark" content="Edit" placement="top">
            <router-link
              :to="{
                name: 'SatEditQuestion',
                query: {
                  questionId: question.id
                }
              }"
            >
              <el-button type="warning" size="mini">
                <i class="fas fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="Duplicate"
            placement="top"
          >
            <router-link
              :to="{
                name: 'SatDuplicateQuestion',
                query: {
                  questionId: question.id
                }
              }"
            >
              <el-button type="warning" size="mini">
                <i class="fas fa-copy"></i>
              </el-button>
            </router-link>
          </el-tooltip>
          <!-- <el-tooltip
            class="item"
            effect="dark"
            content="Remove"
            placement="top"
          >
            <el-button
              type="danger"
              size="mini"
              :disabled="!isEdit || hasTaken"
              @click.stop="removeQuestion(question.id)"
            >
              <i class="fas fa-times-circle"></i>
            </el-button>
          </el-tooltip> -->
        </template>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import moment from "moment";
import _ from "lodash";
import MathJax from "@/common/mathJax";

export default {
  components: {},
  mixins: [],
  props: [
    "question",
    "order",
    "isAdd",
    "isEdit",
    "search",
    "canRemove",
    "showPassage",
    "showQuestion",
    "showOptions",
    "showAnswer",
    "showExplanation",
    "showLabels"
  ],
  data() {
    return {
      type: "passage",
      myPassage: 1
    };
  },
  computed: {
    questionTags() {
      let questionTags = null;
      if (this.question.sat_question_tags) {
        questionTags = _.groupBy(this.question.sat_question_tags, "type");
      }
      console.log(questionTags);
      return questionTags;
    }
  },
  watch: {
    passage: {
      handler: function(newValue, oldValue) {
        // let type = "passage";
        this.myPassage = this.passage;
        if (this.passage === 0) {
          this.type = "single";
          this.myPassage = 1;
        } else {
          this.type = "passage";
          this.myPassage = this.passage;
        }
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    isAnswer(answers, answer) {
      let isAnswer = false;
      answers.forEach(e => {
        if (e.answers && e.answers.length > 0) {
          e.answers.forEach(item => {
            if (item.indexOf(answer) > -1) {
              isAnswer = true;
            }
          });
        }
      });
      console.log(isAnswer);
      return isAnswer;
    },
    moment() {
      return moment();
    },
    changeColor(result) {
      if (this.search) {
        let replaceReg = new RegExp(this.search, "ig");
        let replaceString = `<span style="background-color: var(--themeColor);color:white;padding:0 0.1rem">${this.search}</span>`;
        result = result.replace(replaceReg, replaceString);
      }
      return result;
    }
  }
};
</script>

<style scoped>
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.question-card {
  font-family: DroidSerif;
}
::v-deep .question-content-title img,
::v-deep .show-passage img {
  display: none;
}
::v-deep .show-FullQuestion .question-content-title img {
  display: block;
  max-width: 400px;
}
::v-deep .el-popper {
  width: 450px !important;
}
.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.ivy-question {
  font-family: DroidSerif;
}
::v-deep .el-button {
  margin: 0 0.3rem;
}
.passage-title {
  position: absolute;
  border: 1px solid #fefefe;
  background: var(--themeColor);
  /* font-size: 16px; */
  color: white;
  left: -2px;
  top: -15px;
  height: 34px;
  padding: 0 17px;
  line-height: 32px;
  border-radius: 5px 5px 0 0;
}
.question-content {
  position: absolute;
  right: -5px;
  top: -25px;
}
.question-content-title {
  /* font-size: 1.4rem; */
}
/* ::v-deep .question-content-title img {
  display: block;
  max-width: 60%;
  max-height: 250px;
  margin: 10px auto 20px;
} */
::v-deep .question-content-title p {
  margin: 0;
}
.remove-question {
  cursor: pointer;
  position: absolute;
  /* font-size: 18px; */
  right: -10px;
  top: -10px;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
}
.tpos {
  display: block;
  margin: 10px 0;
  padding: 10px 20px;
  text-decoration: none;
  cursor: pointer;
  border-radius: 10px;
  box-sizing: border-box;
  background-color: #edf1f8;
  border-bottom: 2px solid #dadee5;
  color: #808080;
  transition: 0.2s 0s;
  border-radius: 5px;
}
.tpos:hover {
  box-shadow: 3px 5px 10px #aaa;
  color: var(--themeColor);
}
</style>
<style scoped>
.question-order {
  margin: 10px 0 10px 0;
  background-color: #ccc;
}
kbd {
  padding-right: 15px;
  padding-left: 15px;
}
.answer_content {
  justify-content: space-around;
}
.cover {
  height: calc(100vh - 200px);
}
.question-order {
  margin: 20px 0;
  background-color: #ccc;
}
.ivy-question {
  position: relative;
  overflow: visible;
  background-color: #f8f8f8;
}
.add-button {
  position: absolute;
  top: 50%;
}
.ivy-question .el-card {
  overflow: visible;
}
.ivy-question-options {
  /* font-size: 16px; */
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  /* font-size: 16px; */
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.ivy-tag {
  margin: 5px;
}
.hidden {
  display: none;
}
::v-deep .el-card__body {
  padding: 15px;
}
::v-deep .MathJax .mrow {
  /* font-size: 1rem !important; */
}
::v-deep .highlight {
  background-color: var(--themeColor);
  color: white;
  padding: 0 0.1rem;
}
.is-warning {
  background-color: #c0c4cc;
}
</style>
