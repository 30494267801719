<template>
  <div>
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          {{ myTitle }}
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <Heading heading="h4" :content="myTitle">
      <el-tooltip
        slot="button"
        class="item"
        effect="dark"
        content="Create"
        placement="top"
        v-if="isAdmin"
      >
        <router-link
          :to="{
            name: 'SatAddQuestion'
          }"
        >
          <el-button type="success" size="mini"
            ><i class="fa fa-plus"></i
          ></el-button>
        </router-link>
      </el-tooltip>
    </Heading>
    <hr />
    <AllQuestions
      :isAdmin="isAdmin"
      :isTeacher="isTeacher"
      :isCompany="isCompany"
    />
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import AllQuestions from "@/views/allQuestions/Index.vue";
import Breadcrumb from "@/components/Breadcrumb";
export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {
    AllQuestions,
    Breadcrumb
  },

  mixins: [],

  props: [],
  data() {
    return {
      search: {}
    };
  },
  computed: {
    myTitle() {
      return this.$t("sat.QuestionsBox");
    },
    isAdmin() {
      return this.$route.name === "AllQuestions";
    },
    isTeacher() {
      return this.$route.name === "TeacherAllQuestions";
    },
    isCompany() {
      return this.$route.name === "CompanyAllQuestions";
    }
  },
  watch: {},

  async mounted() {},

  methods: {}
};
</script>

<style scoped>
.container {
  background-color: white;
  padding-top: 0;
  padding: 40px 40px 20px 40px;
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
